import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AlertService } from './../../../../_service/alert.service';
import { AuthHelper } from '../../../../_helper/auth';
import { UrlHelper } from '../../../../_helper/url';
import { ServiceService } from '../../../../_service/service.service';
import { CalendarModule } from 'primeng/calendar';
import * as moment from 'moment-timezone';
import { NgbModal, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StringHelper } from 'src/app/_helper/string';
import { DateHelper } from 'src/app/_helper/date';

@Component({
  selector: 'app-new-booking-datetime-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.css']

})
export class AccommodationComponent implements OnInit {

  @Input() config: any;
  @Output() emit_config = new EventEmitter<any>();
  en: any;
  no: any;
  customer_calendar_ui_mode = '0';
  available_cals = [];

  today: any;
  datetime = [];
  datetime_s = null;
  datetime_e = null;
  modal_data: any;
  selected_date = 0;
  is_next = false;
  cals: any;
  slots_data; any;
  is_customer = true;
  is_provider = false;
  is_guest = false;
  unavailable_dates: any;

  constructor(
    public alertService: AlertService,
    public auth: AuthHelper,
    public dateHelper: DateHelper,
    public translate: TranslateService,
    private modalService: NgbModal,
    private stringHelper: StringHelper,
    public serviceService: ServiceService,
    public urlHelper: UrlHelper
  ) { }

  ngOnInit() {

    this.today = moment().add(1, 'days').toDate();

    if (this.auth.isLoggedIn()) {
      if (this.auth.getUser().user_type != 'customer') {
        this.is_customer = false;
        this.is_provider = true;
      }
    }
    else {
      this.is_guest = true;
      this.is_customer = false;
    }

    // below moment need to be like this, it must assume local exact time
    this.selected_date = moment().utcOffset(0, true).unix();
    this.en = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear'
    };
    this.no = {
      firstDayOfWeek: 1,
      dayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
      dayNamesShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
      dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
      monthNames: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
      today: 'I dag',
      clear: 'Klar'
    };
    this._getSlots();
  }


  /**
   * 
   */
  _getSlots() {
    this.config.is_loading = true;
    let _p = { "date": this.selected_date, config: JSON.stringify(this.config), "subdomain": this.urlHelper.getSubdomain() };

    this.serviceService.getAvailableSlots2(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.config.is_loading = false;

      if (temp.success) {
        this.cals = temp.data;
        console.log(this.cals);
        this.slots_data = temp;
        this.customer_calendar_ui_mode = temp.customer_calendar_ui_mode;
        if (this.is_provider) {
          this.customer_calendar_ui_mode = "1";
        }
        if (this.is_customer || this.is_guest) {
          // temp._s. detailed_description below is of service not calendar
          // its only hack to show detailed description for accommodation service for customer side
          if (temp.customer_calendar_ui_mode == '2') {
            // TODO, below checkin and chekcoug stack, needd to fix if from multiple cal
            // need to merge from api, this apply only to customer
            this.cals = [];
            this.cals.push({
              "id": 0, "name": this.config.summary.service, "description": temp._s.detailed_description,
              "unavailable_slots": []
            });
          }
        }
      }
      else {
        this.cals = [];
        this.alertService.error(temp.msg);
      }

    });
  }

  getMomentTime(arr) {
    let new_arr = [];
    arr.forEach((d, k) => {
      new_arr.push(new Date(Number(d) * 1000));
      // console.log(new Date(Number(d) * 1000));
    });

    return new_arr;

  }

  /**
   * 
   * @param date 
   * @param cal 
   */
  checkDateType(date, cal) {
    if (this.ifAllowedCheckout(date, cal)) {
      return "danger";
    }
    return "dark";

  }

  /**
   * 
   * @param date 
   */
  ifAllowedCheckout(date, cal) {
    let _d = moment(date.day + "." + date.month + 1 + "." + date.year, "DD.M.YYYY").utc().unix();
    if (cal.checkin_stack != null) {
      if (cal.checkin_stack.includes(_d)) {
        return true;
      }
    }
    return false;
  }


  onClick_Back() {
    this.config.step = "service";
    this.emit_config.emit(this.config);
  }

  /**
   * 
   * @param e 
   * @param cal 
   */
  onClick_DatetimeChange(e, cal) {
    this.is_next = false;
    e.setHours(0);
    e.setMinutes(0);
    e.setSeconds(0);
    this._makeDatesForOption1(e, cal);

  }

  /**
   * 
   * @param e 
   * @param cal 
   */
  onClick_DatetimeChange_2(e) {
    this.is_next = false;
    e.setHours(0);
    e.setMinutes(0);
    e.setSeconds(0);
    if (this.datetime_s != null && this.datetime_e != null) {
      this.datetime_s = null;
      this.datetime_e = null;
    }

    if (this.datetime_s == null) {
      this.datetime_s = e;
      this.datetime_e = null;
      this.available_cals = [];
    }
    else {
      this.datetime_e = e;
    }
    if (this.datetime_s != null && this.datetime_e != null) {

      let s = moment(this.datetime_s).utcOffset(0, true);
      let e = moment(this.datetime_e).utcOffset(0, true);

      if (e > s) {
        this.config.summary.start_ts = s.unix();
        this.config.summary.starts = s.format("LL");

        this.config.summary.ends = e.format("LL");
        this.config.summary.end_ts = e.unix();

        let days = (this.config.summary.end_ts - this.config.summary.start_ts) / (60 * 60 * 24);
        if (this.config.summary.end_ts == this.config.summary.start_ts) {
          days = 1;
        }
        this.config.service.forEach((k, i) => {
          k.start_ts = s.unix();
          k.end_ts = e.unix();
        });

        this._option2(e);
      }
      else {
        this.datetime_s = this.datetime_e;
        this.datetime_e = null;
      }
    }
  }

  /**
   * 
   * @param e 
   * @param cal 
   */
  _option2(e) {
    let _temp = this.config;
    //_temp.service[0].description = "";
    //_temp.service[0].detailed_description = "";
    let _p = { "customer_calendar_ui_mode": this.customer_calendar_ui_mode, "params": JSON.stringify(_temp) };
    this.serviceService.getAvailableRooms(_p).subscribe(data => {
      let temp: any;
      temp = data;
      this.available_cals = temp.data;
      if (this.available_cals.length > 0) {
        this.cals[0].id = this.available_cals[0].id;
        this.config.calendar_id = this.cals[0].id;
        this.datetime[this.config.calendar_id] = [];
        this.datetime[this.config.calendar_id][0] = this.datetime_s;
        this.datetime[this.config.calendar_id][1] = this.datetime_e;

        this._makeDatesForOption2(e, this.cals[0]);

      }
      else {
        this.cals[0].id = 0;
        this.config.calendar_id = 0;
      }
      //console.log(this.cals[0].id);
    });

  }


  /**
   * 
   * @param e 
   * @param cal 
   */
  _makeDatesForOption1(e, cal) {
    this.datetime.forEach((k, i) => {
      if (cal.id != i) {
        this.datetime[i] = null;
      }
    });
    let s = moment(this.datetime[cal.id][0]).utcOffset(0, true);
    if (this.datetime[cal.id][0] != null && this.datetime[cal.id][1] == null) {
      this.config.summary.start_ts = s.unix();
      if (this.validateStart(cal)) {
        this.config.summary.starts = s.format("LL");
        //this.alertService.info(this.translate.instant("alert-please-select-end-date"));
      }
    }

    if (this.datetime[cal.id][0] != null && this.datetime[cal.id][1] != null) {
      let e = moment(this.datetime[cal.id][1]).utcOffset(0, true);

      this.config.summary.ends = e.format("LL");
      this.config.summary.end_ts = e.unix();

      if (this.config.summary.end_ts != "" && this.config.summary.start_ts != "") {
        let days = (this.config.summary.end_ts - this.config.summary.start_ts) / (60 * 60 * 24);

        if (this.config.summary.end_ts == this.config.summary.start_ts) {
          days = 1;
        }

        this.config.payment.balance = this.config.summary.price = days * this.config.service[0].price + Number(this.config.service[0].cleaning_fee);
        this.config.summary.duration = days;
        this.config.service.forEach((k, i) => {
          k.start_ts = s.unix();
          k.end_ts = e.unix();
        });

        if (this.validateEnd(cal)) {
          this.is_next = true;
        }
        else {
          this.config.summary.ends = "";
          this.config.summary.end_ts = "";
          this.config.summary.duration = 0;

        }
      }
    }
    this.config.calendar_id = cal.id;
  }

  /**
   * 
   * @param e 
   * @param cal 
   */
  _makeDatesForOption2(e, cal) {
    this.datetime.forEach((k, i) => {
      if (cal.id != i) {
        this.datetime[i] = null;
      }
    });
    if (this.datetime[cal.id][0] != null && this.datetime[cal.id][1] != null) {
      let s = moment(this.datetime[cal.id][0]).utcOffset(0, true);
      this.config.summary.start_ts = s.unix();
      this.config.summary.starts = s.format("LL");

      let e = moment(this.datetime[cal.id][1]).utcOffset(0, true);
      this.config.summary.ends = e.format("LL");
      this.config.summary.end_ts = e.unix();

      if (this.config.summary.end_ts != "" && this.config.summary.start_ts != "") {
        let days = (this.config.summary.end_ts - this.config.summary.start_ts) / (60 * 60 * 24);
        if (this.config.summary.end_ts == this.config.summary.start_ts) {
          days = 1;
        }

        this.config.payment.balance = this.config.summary.price = days * this.config.service[0].price + Number(this.config.service[0].cleaning_fee);
        this.config.summary.duration = days;
        this.config.service.forEach((k, i) => {
          k.start_ts = s.unix();
          k.end_ts = e.unix();

        });
        if (this.validateEnd(cal)) {
          this.is_next = true;
        }
        else {
          this.config.summary.ends = "";
          this.config.summary.end_ts = "";
          this.config.summary.duration = 0;
          this.config.summary.price = 0;

        }
      }
    }
  }


  /**
   * 
   */
  onClick_Next() {
    this.config.step = "customer";
    this.emit_config.emit(this.config);

  }

  /**
   * 
   */
  validateStart(cal) {

    // how far ahead    

    let today_ts = moment(this.today).utcOffset(0, true).unix();
    let that_far = today_ts + this.config.service[0].how_far_ahead * 60 * 60 * 24;
    if (this.config.summary.start_ts > that_far) {
      this.config.summary.start_ts = "";
      this.alertService.error(this.translate.instant("alert-cannot-book-that-far-ahead"));
      return false;
    }
    // else all ok
    return true;
  }

  /**
  * 
  */
  validateEnd(cal) {

    // below is commented on 5 march 2021, not sure why
    //if (!this.is_customer && !this.is_guest) {
    // for customer_calendar_ui_mode == 2 we are checking below if on service side
    if (this.customer_calendar_ui_mode == '1') {
      for (let i = this.config.summary.start_ts; i < this.config.summary.end_ts;) {
        i = i + (60 * 60 * 24);
        if (this.stringHelper.hasSomething(cal.start_end_only_unavailable_slots)) {
          if (cal.start_end_only_unavailable_slots.indexOf(i) > -1) {
            this.alertService.error(this.translate.instant("alert-one-or-more-days-not-available-for-booking"));
            return false;
          }
        }
        if (this.stringHelper.hasSomething(cal.unavailable_slots)) {
          if (cal.unavailable_slots.indexOf(i) > -1) {
            this.alertService.error(this.translate.instant("alert-one-or-more-days-not-available-for-booking"));
            return false;
          }
        }
      }
    }

    // WARNING, this flag is directly set in DB and one of customer is using it
    // May be it is set on one of server on live system, and is one of hard coded requirement
    // on 20 oct 2022 we are making it dynamic
    if (this.config.service[0].is_allowed_same_day_checkin_checkout == '0') {
      // console.log(this.config.summary.start_ts + "=" + this.config.summary.end_ts);
      if (this.config.summary.start_ts == this.config.summary.end_ts) {
        this.alertService.error(this.translate.instant("alert-cannot-checkin-checkout-same-day"));
        return false;
      }
    }

    if (this.config.summary.duration > this.config.service[0].max_duration / (60 * 24)) {
      this.alertService.error(this.translate.instant("alert-you-exceeding-max-duration-allowed"));
      return false;
    }

    // how far ahead    
    let today_ts = moment(this.today).utcOffset(0, true).unix();
    let that_far = today_ts + this.config.service[0].how_far_ahead * 60 * 60 * 24;
    if (this.config.summary.start_ts > that_far) {
      this.alertService.error(this.translate.instant("alert-cannot-book-that-far-ahead"));
      return false;
    }

    // else all ok
    return true;
  }

  /**
   * 
   */
  _getTimestampDateUserWantToBook() {
    let stack = [];
    let i;
    for (i = this.config.summary.start_ts; i <= this.config.summary.end_ts;) {
      stack.push(i);
      i = i + (60 * 60 * 24);
    }
    return stack;
  }

  /**
   * 
   * @param modal 
   * @param item 
   */
  open(modal, item) {
    this.modal_data = item;
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  /**
   * 
   * @param cal 
   * @param date 
   */
  isHalfCheckinDay(cal, date) {
    if (cal.checkin_stack != undefined) {
      return cal.checkin_stack.includes(this.dateHelper.makeDate(date.year, date.month, date.day))
    }
    return true;
  }

  /**
   * 
   * @param cal 
   * @param date 
   */
  isHalfCheckoutDay(cal, date) {
    if (cal.checkout_stack != undefined) {
      return cal.checkout_stack.includes(this.dateHelper.makeDate(date.year, date.month, date.day));
    }
    return true;

  }

}
