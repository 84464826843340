<nav aria-label="breadcrumb" role="navigation" class="bg-primary text-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col">
          <ol class="breadcrumb" >
            <li class="breadcrumb-item"><a href="javascript:void(0);" translate >txt-account-config</a>
            </li>
            <li *ngIf="is_customer == true"  class="breadcrumb-item active" aria-current="page" translate >
                txt-manage-your-profile-and-change-password</li>
            <li *ngIf="is_customer == false"  class="breadcrumb-item active" aria-current="page" translate>
                txt-configure-your-calendar-services-profile-and-security-settings</li>
          </ol>
        </div>
        <!--end of col-->
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </nav>
  
  <section class="flush-with-above space-0">
    <div class="bg-white">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" >
                            <a class="nav-link show active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="general" aria-selected="true" translate >btn-account</a>
                        </li>
                        <li class="nav-item" *ngIf="is_customer == false && auth.getUser().is_allowed_config == '1' " >
                            <a class="nav-link" id="portfolio-tab" data-toggle="tab" href="#portfolio" role="tab" aria-controls="portfolio" aria-selected="false" translate >btn-portfolio</a>
                        </li>
                        <li class="nav-item"  *ngIf="is_customer == false && auth.getUser().is_allowed_config == '1' " >
                            <a class="nav-link" (click)="onClick_Calendar()" id="calendar-tab" data-toggle="tab" href="#calendar" role="tab" aria-controls="billing" aria-selected="false" translate >btn-calendars</a>
                        </li>
                        <li class="nav-item"  *ngIf="is_customer == false && auth.getUser().is_allowed_config == '1' " >
                            <a class="nav-link" (click)="onClick_Service()" id="service-tab" data-toggle="tab" href="#service" role="tab" aria-controls="billing" aria-selected="false" translate >btn-services</a>
                        </li>
                        <li class="nav-item"     *ngIf="is_customer == false && auth.getUser().is_allowed_config == '1' " >
                            <a class="nav-link" id="payment-tab" data-toggle="tab" href="#payment" role="tab" aria-controls="billing" aria-selected="false" translate >btn-payment</a>
                        </li>
                        <li class="nav-item"   *ngIf="is_customer == false && auth.getUser().is_allowed_config == '1' " >
                            <a class="nav-link" id="user-tab" data-toggle="tab" href="#user" role="tab" aria-controls="billing" aria-selected="false" translate >btn-users</a>
                        </li>
                        <li class="nav-item"  *ngIf="is_customer == false && auth.getUser().is_allowed_config == '1' " >
                            <a class="nav-link" id="templates-tab" data-toggle="tab" href="#templates" role="tab" aria-controls="templates" aria-selected="false" translate >btn-templates</a>
                        </li>
                       <!-- <li class="nav-item"  *ngIf="is_customer == false && auth.getUser().is_allowed_config == '1' " >
                            <a class="nav-link" id="coupons-tab" data-toggle="tab" href="#coupons" role="tab" aria-controls="coupons" aria-selected="false" translate >btn-coupons</a>
                        </li> -->
                        <li class="nav-item" (click)="onClick_Misc()" *ngIf="is_customer == false && auth.getUser().is_allowed_config == '1'" >
                            <a class="nav-link" id="global-tab" data-toggle="tab" href="#global" role="tab" aria-controls="global" aria-selected="false" translate >btn-misc</a>
                        </li>
                    </ul>
                </div>
                <!--end of col-->
            </div>
            <!--end of row-->
        </div>
        <!--end of container-->
    </div>
  </section>
  <div class="pt-4 flush-with-above height-80 d-block">
    <div class="tab-content">
        <div class="tab-pane fade active show" id="profile" role="tabpanel" >
            <app-config-profile></app-config-profile>
        </div>
        <div class="tab-pane fade" id="calendar" role="tabpanel"  *ngIf="is_customer == false"  >
            <app-config-calendar ></app-config-calendar>
        </div>
        <div class="tab-pane fade" id="service" role="tabpanel"  *ngIf="is_customer == false"  >
            <app-config-service ></app-config-service>
        </div>
        <div class="tab-pane fade" id="payment" role="tabpanel"  >
            <app-config-payment></app-config-payment>
        </div>
        <div class="tab-pane fade" id="user" role="tabpanel"  *ngIf="is_customer == false"  >
            <app-config-user></app-config-user>
        </div>
        <div class="tab-pane fade" id="templates" role="tabpanel"  *ngIf="is_customer == false"  >
            <app-config-templates></app-config-templates>
        </div>
        <div class="tab-pane fade" id="coupons" role="tabpanel"  *ngIf="is_customer == false"  >
            <app-config-coupons></app-config-coupons>
        </div>
        <div class="tab-pane fade" id="portfolio" role="tabpanel"  *ngIf="is_customer == false"  >
            <app-config-portfolio></app-config-portfolio>
        </div>
        <div class="tab-pane fade" id="global" role="tabpanel"  *ngIf="is_customer == false"  >
            <app-config-global></app-config-global>
        </div>
    </div>  
</div>
  