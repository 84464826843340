import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css']
})
export class CouponsComponent implements OnInit {

  selected_coupon: any;
  is_loading = false;
  is_user_selected = false;
  all_users:any;
  is_coupon_selected = false;
  title_service = "Attach Services";
  title="Manage Coupons";
  all_services:any;
  public status = [{ "value": "active" }, { "value": "inactive" }];
  public boolean_status = [{ "value": "1" }, { "value": "0" }];

  constructor() { 
    this.selected_coupon = {
      "id": "", "name": "", "discount": "","is_active_forever":0,
      "starts": "", "ends": "","status":""
    };

  }

  ngOnInit() {
  }

  onClick_Coupons(e, id) {

  }

  onClick_AddCoupon() {

  }

  onClick_Save() {

  }

  onClick_TryDelete() {

  }

  onClick_ModalClose() {


  }

  onClick_Delete() {

  }

}
