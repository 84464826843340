<div class="container">
    <div class="row">
        <!--end of col-->
        <div class="col-12 col-md-3">
            <div class="card card-sm">
                <ul class="list-group">
                    <li class="list-group-item bg-light">
                        <button class="btn btn-block btn-link text text-muted"
                            (click)="onClick_AddCoupon()"
                            translate>btn-add</button>
                    </li>
                    <li class="list-group-item" id="itm{{itm.id}}" *ngFor="let
                        itm of all_users"
                        (click)="onClick_User($event,itm.id)"><a
                            href="javascript:void(0);"
                            id="itm{{itm.id}}">{{itm.username}}</a>
                        <div>
                            <small *ngIf="itm.status=='active'" class="badge
                                badge-light border text-muted">{{itm.status}}</small>
                            <small *ngIf="itm.status=='inactive'" class="badge
                                badge-secondary">{{itm.status}}</small>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-md-9">
            <div class="card card-sm">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <span *ngIf="is_loading == false">{{title}}</span>
                    <span *ngIf="is_loading == true" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif">
                        txt-loading
                    </span>
                </div>
                <div class="card-body">
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-coupon-name</label> <br>
                            <input class="form-control form-control-lg"
                                type="text" autocomplete="off" maxlength="200"
                                [(ngModel)]="selected_coupon.name" />
                        </div>
                       
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-coupon-count</label> <br>
                            <input class="form-control form-control-lg"
                                type="number" autocomplete="off" maxlength="10"
                                [(ngModel)]="selected_coupon.name" />
                        </div>
                        <div class="col">
                            <label translate>lbl-coupon-discount</label> <br>
                            <input class="form-control form-control-lg"
                                type="text" autocomplete="off" maxlength="200"
                                placeholder="e.g. 10%"
                                [(ngModel)]="selected_coupon.discount" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <label translate>lbl-coupon-starts</label><br>
                            <input class="form-control form-control-lg"
                                type="date"
                                [(ngModel)]="selected_coupon.first_name" />
                        </div>
                        <div class="col">
                            <label translate>lbl-coupon-ends</label><br>
                            <input class="form-control form-control-lg"
                                type="date"
                                [(ngModel)]="selected_coupon.last_name" />
                        </div>
                    </div>
  

                    <div class="row form-group mt-3">
                        <div class="col">
                            <div class="custom-control custom-checkbox
                                custom-checkbox-switch">
                                <input type="hidden"
                                    [(ngModel)]="selected_coupon.status">
                                <input type="checkbox"
                                    class="custom-control-input"
                                    [checked]="selected_coupon.status ===
                                    status[0].value"
                                    (change)="$event.target.checked?
                                    (selected_coupon.status= status[0].value) :
                                    (selected_coupon.status= status[1].value)"
                                    id="status">
                                <label class="custom-control-label" for="status"
                                    translate>lbl-one-use-per-customer</label>
                                    <small translate="">txt-one-use-per-customer-info</small>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card-footer bg-light">
                    <button *ngIf="is_loading == true" class="btn btn-lg text
                        text-muted btn-light" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif"
                            translate> txt-loading
                    </button>
                    <button *ngIf="is_loading == false" class="btn btn-lg
                        btn-primary" (click)="onClick_Save()"
                        translate>btn-save</button>
                    <button *ngIf="is_user_selected == true && is_loading ==
                        false"
                        class="btn btn-outline-danger btn-lg float-right"
                        (click)="onClick_TryDelete()"
                        translate>btn-delete</button>
                </div>

            </div>

            <div class="card card-sm" *ngIf="is_coupon_selected == false">
                <div class="card-header bg-secondary d-flex
                    justify-content-between align-items-center">
                    <span *ngIf="is_loading == true" translate>
                        <img src="assets/img/ajax-loader-dark-gray.gif"
                            translate>
                        txt-loading
                    </span>
                    <span *ngIf="is_loading == false">{{title_service}}
                    </span>
                </div>
                <div class="">
                    <ul class="list-group">
                        <li class="list-group-item" id="cat_li_{{cat.id}}"
                            *ngFor="let cat of all_services;let i= index"><span
                                id="cat_span_{{cat.id}}">{{cat.name}}

                                <span class="float-right custom-control
                                    custom-checkbox custom-checkbox-switch">
                                    <input type="checkbox"
                                        class="custom-control-input"
                                        (change)="onClick_SelectCategory($event,cat)"
                                        [checked]="cat.title=='btn-assign'"
                                        id="cat{{cat.id}}">
                                    <label class="custom-control-label"
                                        for="cat{{cat.id}}"></label>
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--end of row-->
    </div>

    <!--end of container-->
    <ngx-smart-modal identifier="user_id">
        <h6 class="h4 mb-2 text text-danger" translate>heading-are-you-sure</h6>
        <hr class="mt-2 mb-2">
        <div class="h6 alert" translate="">
            txt-warning-this-may-delete-or-disabled-some-data-related-to-this-item
        </div>
        <div class="fixed-bottom p-2 border-top">
            <button (click)="onClick_ModalClose();" class="btn btn-lg
                btn-secondary
                float-left" translate="">btn-cancel</button>
            <button (click)="onClick_Delete();" class="btn btn-lg btn-danger
                float-right" translate="">btn-submit</button>
            <!--   <button (click)="onClick_Delete();deleteModal.close()"  class="btn btn-md btn-danger float-right" >Confirm !</button>
  -->
        </div>
    </ngx-smart-modal>