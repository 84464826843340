import { Component, OnInit, ViewChild, ComponentFactoryResolver, AfterViewInit, SimpleChanges, AfterContentInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModule, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BusinessService } from './../../_service/business.service';
import { CategoryService } from './../../_service/category.service';
import { CalendarService } from './../../_service/calendar.service';
import { ServiceService } from './../../_service/service.service';
import { BookingService } from './../../_service/booking.service';
import { CustomerService } from './../../_service/customer.service';
import { AppointmentComponent } from './appointment/appointment.component';
import { CommonComponent } from './common/common.component';
import { RentalComponent } from './rental/rental.component';
import { AccommodationComponent } from './accommodation/accommodation.component';
import { PayComponent } from './pay/pay.component';
import { CustomerComponent } from './customer/customer.component';
import { CategoryComponent } from './category/category.component';
import { ServiceComponent } from './service/service.component';
import { environment } from '../../../environments/environment';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';

import { AlertService } from './../../_service/alert.service';
import { AuthHelper } from '../../_helper/auth';
import { UrlHelper } from '../../_helper/url';
import { first } from 'rxjs/operators';
import * as $ from 'jquery';
import * as moment from 'moment-timezone';

const now = new Date();

@Component({
    selector: 'app-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.css']
})
export class CreateComponent implements AfterContentInit {

    @ViewChild(PayComponent,{ static: false }) child_pay: PayComponent;
    @ViewChild(CategoryComponent,{ static: false }) child_category: CategoryComponent;
    @ViewChild(ServiceComponent,{ static: false }) child_service: ServiceComponent;
    @ViewChild(RentalComponent,{ static: false }) child_rental: RentalComponent;
    @ViewChild(CustomerComponent,{ static: false }) child_customer: CustomerComponent;
    @ViewChild(AccommodationComponent,{ static: false }) child_accommodation: AccommodationComponent;
    @ViewChild(AppointmentComponent,{ static: false }) child_appointment: AppointmentComponent;

    url_id = "";
    datetime: NgbDateStruct;
    today: any;
    tomorrow: any;
    err: any;
    current_date: any;

    prev_date: any;
    next_date: any;
    prev_date_string: any;
    next_date_string: any;

    is_loading = false;
    confirmed_bookings = [];
    step_category = true;
    step_service = false;
    step_datetime = false;
    step_customer = false;
    step_customer_login = false;
    step_payment = false;
    step_done = false;
    step_prepare = false;

    title_datetime = "Date/Time"
    is_pay_now_ready = false;
    is_appointment = false;
    is_rental = false;
    is_rental_24hr = false;
    is_return_same_day = false;
    is_require_online_payment = true;
    is_ready_to_select_customer = false;

    is_show_prev_date = true;
    is_show_next_date = true;
    is_show_today_date = true;

    summary: string = "Summary";

    category_id = 0;
    services = [];
    services_name = "";
    services_id = "";

    slot_name = "";
    slot_timestamp = "";
    calendar_name = "";
    calendar_id: any;
    min_duration = 0;
    max_duration = 0;
    break_before = 0;
    break_after = 0;
    total_duration = 0;

    start_datetime: any;
    end_datetime: any;
    start_timestamp: any;
    end_timestamp: any;
    price = 0;
    total_price = 0;

    customer = {
        "username": "",
        "id": 0
    };
    search_customer = "";
    cust: any;
    is_customer = false;
    is_guest = true;

    offset: number;
    subdomain = "";
    business: any;

    payPalConfig?: PayPalConfig;
    is_pay_by_paypal = true;
    is_pay_by_cc = false;
    is_pay_by_saved_card = false;

    is_payment_error = false;
    payment_reference = "";

    /**
     * 
     * @param config 
     * @param businessService 
     * @param serviceService 
     * @param categoryService 
     * @param calendarService 
     * @param alertService 
     */
    constructor(
        private router: Router,
        private config: NgbDatepickerConfig,
        private businessService: BusinessService,
        private serviceService: ServiceService,
        private bookingService: BookingService,
        private url: UrlHelper,
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private alertService: AlertService,
        private auth: AuthHelper
    ) {
        if (this.auth.isLoggedIn()) {
            if (this.auth.getUser().user_type != 'customer' && this.auth.getUser().is_allowed_new_booking != '1') {
                this.router.navigate(['/dashboard']);
            }
        }

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.subdomain = url.getSubdomain();
        this.today = new Date();
        //console.log("Config variable is disabled here !!");
        /**
        config.minDate = {
            year: now.getFullYear(),
            month: now.getMonth() + 1,
            day: now.getDate()
        };
        config.maxDate = {
            year: 2099,
            month: 12,
            day: 31
        };
        config.outsideDays = 'hidden';
        */

        if (this.auth.isLoggedIn()) {
            if (this.auth.getUser().user_type == "customer") {
                this.is_customer = true;
                this.customer.username = this.auth.getUser().username;
                this.customer.id = this.auth.getUser().id;
            }
            else {
                this.is_guest = false;
                this.is_customer = false;
            }
        }
        else {
            this.is_guest = true;
            this.customer.username = "Guest";
        }

    }


    ngAfterContentInit() {
        this._checkUrl();

        if (this.subdomain != "") {
            this._findBusiness();
        } else {
            this.alertService.error("Error, you must try a valid subdomain");
        }

    }
    /**
     * 
     */
    _checkUrl() {
        // see if anything sent in url
        this.route.queryParams.subscribe(params => {
            if (params['id'] != "" && params['id'] != undefined && Number(params['id']) > 0) {
                this.url_id = params['id'];
                this._findBusiness(function () {
                    
                    this.serviceService.getById(this.url_id).subscribe(data => {
                        let temp: any;
                        temp = data;
                        //this.break_after = Number(temp.data.break_after)*60;
                        //this.break_before = Number(temp.data.break_before)*60;
                        this.min_duration = Number(temp.data.duration) ;
                        this.max_duration = Number(temp.data.max_duration) ;
                        this.total_price = temp.data.price;
                        this.price = temp.data.price;
                        this.services_id = temp.data.id;
                        this.services_name = temp.data.name;
                        this.step_category = false;
                        this.step_service = false;
                        this.step_datetime = true;
                        if (temp.data.service_type == "1") {
                            this.is_appointment = true;
                        }
                        if (temp.data.service_type == "2") {
                            this.is_rental = true;
                        }
                        if (temp.data.service_type == "3") {
                            this.is_rental_24hr = true;
                        }
                        
                        this.onClick_Today();
                    });
                }.bind(this));
            }
            if (params['c_id'] != "" && params['c_id'] != undefined && Number(params['c_id']) > 0) {
                this.url_id = params['c_id'];
                this._findBusiness(function () {
                    this.category_id = this.url_id;
                    this.step_category = false;
                    this.step_service = true;
                    this._getAllServices();
                }.bind(this));
            }

        });
    }

    /**
     * 
     */
    ngOnInit() {
    }

    /**
     * 
     */
    _findBusiness(cb = null) {
        this.is_loading = true;
        this.businessService.getBySubdomain(this.subdomain).subscribe(data => {
            let temp: any;
            temp = data;
            if (temp.success) {
                if (temp.data.id != undefined) {
                    this.business = temp.data;
                    // below login only take care of internal booking process
                    // kicks out is user not login.
                    if (Number(this.business.enable_internal_booking) == 1) {
                        if (this.auth.isLoggedIn() == false) {
                            // this.router.navigate(['/signin']);
                        }
                    }
                    if (cb != null) {
                        cb();
                    }
                    else {
                        this.price = 0;
                        if (this.business.enable_categories == '1') {
                            this._getAllCategories();
                        }
                        else {
                            // sent -1 mean you want to load all service
                            // mean category is disabled by provider
                            this.calendar_id = -1;
                            this.summary = "Summary";
                            this.step_category = false;
                            this.step_service = true;
                            this._getAllServices();
                        }
                    }
                }
            }
            else {
                this.subdomain = "";
                this.alertService.error("Business " + this.subdomain + " does not exists.");
            }
            this.is_loading = false;
        });
    }

    /**
     * 
     * @param event 
     */
    onSelect_Category(obj) {
        let id = obj.id;
        this.summary = obj.name;
        this.category_id = id;
        this.child_service.getAllServices(id, this.business);
        this.step_category = false;
        this.step_service = true;
    }


    /**
     * 
     * @param event 
     */
    onSelect_Service(obj) {
        this.price = obj.price;
        if (obj.service_type == 1) {
            this.total_price = obj.price;
        }
        this.min_duration = obj.min_duration;
        this.max_duration = obj.max_duration;
        this.break_after = obj.break_after;
        this.break_before = obj.break_before;
        this.services_id = obj.services_id;
        this.services_name = obj.services_name;
    }

    /**
     * 
     */
    onClick_NextBtnOnService(obj: any) {
        this.services = obj;
        this.is_appointment = obj.is_appointment;
        this.is_rental = obj.is_rental;
        this.is_require_online_payment = obj.is_require_online_payment;
        this.is_rental_24hr = obj.is_rental_24hr;
        this.is_return_same_day = obj.is_return_same_day;
        this.step_service = false;
        this.step_datetime = true;
        this._reset();
        this.child_customer.reset();
        this.child_rental.reset();

        this.current_date = moment().utcOffset(0, true).startOf("day");
        let ts = this.current_date.unix();
        this._setNextPrevDate();
        this._getSlotsByDate(ts);
    }

    /**
     * 
     */
    _reset() {
        this.start_datetime = "";
        this.end_datetime = "";
        this.start_timestamp = "";
        this.end_timestamp = "";
        this.calendar_id = "";
        this.calendar_name = "";
        this.total_duration = 0;
        if (this.is_customer == false) {
            this.customer.id = 0;
            this.customer.username = "";
        }
        if (this.is_rental == true) {
            this.total_price = 0;
        }
    }

    /**
     * 
     * @param obj 
     */
    onSelect_Slot(obj) {
        // min_duration is actually the total duration for the service
        // applies to all service type
        if (obj.jump_to_date == true) {
            this.child_rental.reset();
            this._getSlotsByDate(obj.ts);
            this.current_date = obj.current_date;
            this._reset();
            this._setNextPrevDate();
        }
        else {
            this.slot_timestamp = obj.slot_timestamp;
            this.slot_name = obj.slot_name;

            this.calendar_name = obj.calendar_name;
            this.calendar_id = obj.calendar_id;
            this.price = obj.price;

            this.start_timestamp = obj.start_timestamp;
            this.start_datetime = obj.start_datetime;
            this.end_timestamp = obj.end_timestamp;
            this.end_datetime = obj.end_datetime;

            this.total_duration = obj.total_duration;
            // for service its the min duration to book a item
            // its used to calculate end slot list
            if (this.is_rental) {
                this.slot_name = obj.start_slot_name;
                this.min_duration = obj.min_duration;
                this.max_duration = obj.max_duration;
                this.total_price = this.price * obj.qty;
            }
            if (this.is_rental_24hr) {
                this.slot_name = "";
                this.total_price = (this.price * obj.qty) + Number(obj.cleaning_fee);
            }

            // if not loggedin or provider logon
            if (this.is_customer == false) {
                this.step_datetime = false;
                if (this.is_guest == true) {
                    this.step_customer_login = true;
                } else {
                    this.step_customer = true;
                }
            } else {
                this.step_datetime = false;
                this.step_payment = true;
                this.is_pay_now_ready = true;
                let payload = this.setParamsForPayload();
                this.child_pay.initPaypalConfig(payload);
            }
        }
    }

    /**
     * 
     * @param event 
     */
    onLoginOrRegister(event) {
        this.is_customer = true;
        this.customer.username = event.username;
        this.customer.id = event.id;
        this.step_customer_login = false;
        this.step_payment = true;
        this.is_pay_now_ready = true;
        let payload = this.setParamsForPayload();
        this.child_pay.initPaypalConfig(payload);
    }

    /**
     * 
     */
    onClick_ConfirmAndPayNextStep() {
        this.step_customer = false;
        this.step_payment = true;
    }

    /**
     * 
     */
    setParamsForPayload() {
        let service_type = 1;
        if (this.is_rental) {
            service_type = 2;
        }
        if (this.is_rental_24hr) {
            service_type = 3;
        }

        this.total_price = +(this.total_price.toFixed(2));

        return {
            "selected_services_id": this.services_id,
            "is_require_online_payment": this.is_require_online_payment,
            "services": this.services,
            "selected_slot": this.slot_name.substring(0, 5),
            "booking_date": moment.unix(this.start_timestamp).unix(),
            "selected_customer": this.customer.id,
            "calendar_id": this.calendar_id,
            "duration": this.total_duration,
            "service_type": service_type,
            "total_price": this.total_price,
            "notes": "",
            "id": ""
        };
    }

    onSuccess_Pay(event) {
        this.step_payment = false;
        this.step_done = true;
        this.step_customer = false;
        if (this.is_require_online_payment == true) {
            event.payment_success = true;
        }
        this.is_loading = true;
        this._pay(event);
    }
    onError_Pay(event) {
        this.step_payment = false;
        this.step_done = false;
        this.step_customer = false;
        this.alertService.error("An Error occurred please try again.");
        this.is_payment_error = true;

    }
    onCancel_Pay(event) {
        this.step_payment = false;
        this.step_done = true;
        this.step_customer = false;
        this._startAgain();
    }

    onConfirmBooking(event) {
        let _p = this.setParamsForPayload();
        _p.notes = event.notes;
        this._checkService(_p);
       // this._pay(_p);
    }

    _checkService(_p) {
        this.is_loading = true;
        this.serviceService.validateForBooking(_p).subscribe(data => {
            let temp: any;
            temp = data;
            this.is_loading = false;
            if (temp.success) {
                this._pay(_p);
            }
            else {
                this.alertService.error("Service not available at request time.");
            }
        });
    }

    /**
     * 
     */
    _pay(_p) {
        if (_p.payment_token != undefined && _p.payment_token != false) {
            _p.payment_token = JSON.stringify(_p.payment_token);
        }//
        else {
            _p.payment_token = "";
        }
        // override in all cases if provider is login
        if (this.is_customer == false) {
            _p.is_require_online_payment = false;
        }
        _p.business_id = this.business.id;
        this.is_loading = true;
        if (this.is_customer) {
            this.step_prepare = false;
        }
        else {
            this.step_prepare = true;
        }
        this.step_customer = false;
        this.bookingService.save(_p).subscribe(data => {
            let temp: any;
            temp = data;
            this.is_loading = false;
            this.step_done = true;
            this.step_prepare = false;
            if (temp.success) {
                temp.data.forEach((v, i) => {
                    if (this.is_appointment == true) {
                        v.starts = moment.unix(v.start_timestamp_in_minute * 60).utc().format("LLL");
                        v.ends = "";
                    }
                    else {
                        v.starts = this.start_datetime;
                        v.ends = this.end_datetime;
                    }
                });
                this.confirmed_bookings = temp.data;
                this.alertService.success(temp.msg);
            } else {
                this.alertService.error(temp.msg);
            }
        },
            error => {
                this.is_loading = false;
                this.alertService.error(error);
            });
    }

    /**
     * 
     */
    _getAllCategories() {
        this.child_category.getAllCategories(this.business);
    }

    /**
     * 
     */
    _getAllServices() {
        this.child_service.getAllServices(this.category_id, this.business);
    }

    /**
     * 
     * @param date_string 
     */
    _getTs(date_string) {
        return moment(date_string).utcOffset(0, true).startOf("day").unix();
    }

    /**
     * 
     */
    onClick_Today() {
        this.current_date = moment().utcOffset(0, true).startOf("day");
        this._getSlotsByDate(this.current_date.unix());
        this._setNextPrevDate();
    }

    /**
     * 
     */
    onClick_NextDate() {
        let ts = this.current_date.add(1, 'd').startOf("day").unix();
        this._getSlotsByDate(ts);
        this._setNextPrevDate();
    }

    /**
     * 
     */
    onClick_PrevDate() {
        let ts = this.current_date.subtract(1, 'd').startOf("day").unix();
        this._getSlotsByDate(ts);
        this._setNextPrevDate();
    }

    /**
     * 
     */
    _setNextPrevDate() {
        let com = new CommonComponent();
        this.next_date_string = com.getNextDateString(this.current_date);
        this.prev_date_string = com.getPrevDateString(this.current_date);
    }

    /**
     * 
     */
    _getSlotsByDate(ts) {
        this.is_loading = true;
        this.err = "";
        this.serviceService.getAvailableSlots(this.services_id, ts, this.business).subscribe(
            (res) => {
                this.is_loading = false;
                let response = JSON.parse(JSON.stringify(res));
                if (response.success) {
                    // send some more data for rental
                    let _p = {
                        "min_duration": this.min_duration,
                        "max_duration": this.max_duration,
                        "break_before": this.break_before,
                        "break_after": this.break_after
                    }
                    if (this.is_appointment == true) {
                        this.child_appointment.generateSlots(response, _p);
                    }
                    if (this.is_rental == true) {
                        this.child_rental.generateSlots(response, _p);
                    }
                    if (this.is_rental_24hr == true) {
                        this.child_accommodation.generateSlots(response);
                    }

                } else {
                    this.err = response.msg;
                }
            },
            error => {
                this.is_loading = false;
                //console.log(error);
                this.alertService.error(error);
            });
    }

    /**
     * 
     * @param obj 
     */
    onSelect_Customer(obj: any) {
        this.customer = obj.customer;
    }

    /**
     * 
     */
    onClick_BackBtnOnPay($event) {
        this.step_payment = false;
        if (this.is_customer == true) {
            this.step_customer = false;

            this.step_datetime = true;
        }
        else {
            this.step_customer = true;
        }
    }

    /**
     * 
     * @param event 
     */
    onClick_BackBtnOnService(event) {
        this.step_category = true;
        this.step_service = false;
        this.child_category.getAllCategories(this.business);
    }

    onClick_BackBtnOnDatetime(event) {
        if (this.category_id != undefined && Number(this.category_id) > 0) {
            this.step_service = true;
            this.step_datetime = false;
            this._getAllServices();
        }
        else {
            this._startAgain();
        }

    }

    onClick_BackBtnOnCustomer(event) {
        this.step_datetime = true;
        this.step_customer = false;
        this.current_date = moment().utcOffset(0, true).startOf("day");
        let ts = this.current_date.unix();
        this._setNextPrevDate();
        this._getSlotsByDate(ts);

    }

    onClick_BackBtnOnLogin(event) {
        this.step_datetime = true;
        this.step_customer_login = false;
        this.current_date = moment().utcOffset(0, true).startOf("day");
        let ts = this.current_date.unix();
        this._setNextPrevDate();
        this._getSlotsByDate(ts);

    }


    onClick_ViewBooking(id) {
        this.router.navigate(['/booking.detail'], {
            queryParams: {
                id: id
            }
        });
    }

    onClick_StartAgain() {
        this._startAgain();
    }

    _startAgain() {
        this.router.navigate(['/booking.create']);
    }
}